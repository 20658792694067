<template>
    <!-- Salary Card -->
    <a-card :bordered="false" class="widget-2 h-full" style="margin-bottom: 13px; box-shadow: inset;">

        <template #title>

            <i class="fa fa-user-circle-o" aria-hidden="true"></i>

        </template>

        <div style="margin: 13px;">

            <a-descriptions :bordered="false" size="small" :column="1">

                <a-descriptions-item label="Title">{{ title }}</a-descriptions-item>
                <a-descriptions-item label="Email" v-if="email">{{ email }}</a-descriptions-item>
                <a-descriptions-item label="Username" v-if="username">{{ username }}</a-descriptions-item>
                <a-descriptions-item label="Phone" v-if="phone">{{ phone }}</a-descriptions-item>


            </a-descriptions>

            <a-button style="width: 100%;" type="primary" @click="viewCustomer">View</a-button>


        </div>



    </a-card>
    <!-- / Salary Card -->
</template>

<script>

export default ({
    props: {
        id: {
            type: String,
            default: "",
        },
        username: {
            type: String,
            default: "",
        },
        email: {
            type: String,
            default: "",
        },
        phone: {
            type: String,
            default: "",
        },
        first_name: {
            type: String,
            default: "",
        },
        last_name: {
            type: String,
            default: "",
        },
    },
    computed : {
        title() {
            return this.first_name!=null && this.last_name!=null?this.first_name + " " + this.last_name: this.username
        }
    },
    methods: {
        viewCustomer() {
            console.log("view channel");
            this.$router.push("/customers/" + this.id);
        }
    },
    data() {
        return {
        }
    },
})

</script>