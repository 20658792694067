<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

        

        <a-row :gutter="24" type="flex">

            <vue-loaders-ball-beat v-if="loading" color="red" scale="1"></vue-loaders-ball-beat>

            <a-col :span="20" class="mb-24">
                <!-- Header Search Input -->
					<a-input-search class="header-search" :class="searchLoading ? 'loading' : ''" placeholder="Type here…" @search="onSearch" :loading='searchLoading' v-model="searchTerm">
						<svg slot="prefix" width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.29583 13.5892 10.4957 12.8907 11.4765L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L11.4765 12.8907C10.4957 13.5892 9.29583 14 8 14C4.68629 14 2 11.3137 2 8Z" fill="#111827"/>
						</svg>
					</a-input-search>
            </a-col>

            <a-col :span="4" class="mb-24">
                <a-button type="primary" block style="margin-left: 9px;">
                    Add Customer
                </a-button>
            </a-col>
            
        </a-row>

        

		<!-- Authors Table -->
		<a-row :gutter="24">

            <p v-if="project_customers.length<1">No items to display, check that you have data sources configured.</p>

			<!-- Authors Table Column -->
            <a-col :span="24">
                <a-table :columns="customerTableColumns" :data-source="project_customers" :pagination="false" :loading="loading">
                    <span slot="Username" slot-scope="text, record">
                        
                        <i class="fa fa-user-circle-o" aria-hidden="true" style="margin-right:9px;"></i>
                        <span class="ml-2">{{ record.username }}</span>
                    </span>
                    <span slot="first_name" slot-scope="text, record">
                        {{ record.first_name }}
                    </span>
                    <span slot="last_name" slot-scope="text, record">
                        {{ record.last_name }}
                    </span>
                    <span slot="email" slot-scope="text, record">
                        {{ record.email }}
                    </span>
                    <span slot="phone" slot-scope="text, record">
                        {{ record.phone }}
                    </span>

                    <span slot="created_at" slot-scope="text, record">
                        {{ record.created_at }}
                    </span>


                    <span slot="actions" slot-scope="text, record">
                        <a-button type="primary">View</a-button>
                    </span>
                </a-table>

			</a-col>
			<!-- / Authors Table Column -->

            

		</a-row>
		<!-- / Authors Table -->

        <br>

        <a-row>
            
         <a-pagination :disabled="searchTerm.length>2" :total="total_project_customers" :current="currentPage" :pageSize="10" @change="onPageChange" />
            
        </a-row>

       

	</div>
</template>

<script>

	// "Authors" table component.
	import CardProjectDisplay from '../components/Cards/CardProjectDisplay' ;

 	// "Projects" table component.
	import WidgetCustomer from '../components/Widgets/WidgetCustomer' ;

    import {fetchDataJSON, fetchPostJSON} from '../services/v1/services';
	

	export default ({
		components: {
			CardProjectDisplay,
			WidgetCustomer,
		},
        beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
        async mounted() {

            const prefrenceRes = await fetchDataJSON('users/profiles/preferences/show', true);

            this.preferences = prefrenceRes.data.preferences;

            if(this.preferences.current_project_id != null){
				this.current_project_id = this.preferences.current_project_id;
			}

            if(this.current_project_id == null) {
                this.error = 'Nothing to display. Please select a project first.';
            }


            const projectCustomersRes = await fetchDataJSON('projects/' + this.current_project_id + '/customers/list?page=' + this.currentPage , true);

            this.project_customers = projectCustomersRes.data.project_customers;

            
            this.total_project_customers = projectCustomersRes.data.total.count;

            this.$eventHub.$on("currentProjectUpdated", async (item) => {
                console.log('currentProjectUpdated indir', item);

                this.current_project_id = item.value;

                const projectCustomersRes = await fetchDataJSON('projects/' + this.current_project_id + '/customers/list?page=' + this.currentPage , true);

                this.project_customers = projectCustomersRes.data.project_customers;
            });

            this.loading = false;
        },
        methods: {
            async onPageChange(page) {
                console.log(page);
                this.currentPage = page;

                this.loading = true;

                const projectCustomersRes = await fetchDataJSON('projects/' + this.current_project_id + '/customers/list?page=' + this.currentPage , true);

                this.project_customers = projectCustomersRes.data.project_customers;

                this.loading = false;
            },
            initCreateProjectChannelModal() {
                this.showNested = true;
                this.$refs.createprojectchannelmodal.open();
            },
            closeNested() {
                this.showNested = false;
            },
            async onSearch(value) {
                this.searchLoading = true;

                console.log('searching', value);

                const projectCustomersRes = await fetchDataJSON('projects/' + this.current_project_id + '/customers/list?term=' + value , true);

                this.project_customers = projectCustomersRes.data.project_customers;

                this.searchLoading = false;
            },
        },
		data() {
			return {

                project_customers: [],

                loading: true,

                error: null,

                searchLoading: false,

                showNested: false,

                currentPage: 1,

                customerTableColumns: [
                    {
                        title: 'Username',
                        dataIndex: 'username',
                        key: 'username',
                        scopedSlots: { customRender: 'Username' },
                    },
                    {
                        title: 'First Name',
                        dataIndex: 'first_name',
                        key: 'first_name',
                        scopedSlots: { customRender: 'first_name' },
                    },
                    {
                        title: 'Last Name',
                        dataIndex: 'last_name',
                        key: 'last_name',
                        scopedSlots: { customRender: 'last_name' },
                    },
                    {
                        title: 'Email',
                        dataIndex: 'email',
                        key: 'email',
                        scopedSlots: { customRender: 'email' },
                    },
                    {
                        title: 'Phone',
                        dataIndex: 'phone',
                        key: 'phone',
                        scopedSlots: { customRender: 'phone' },
                    },
                    {
                        title: 'Created At',
                        dataIndex: 'created_at',
                        key: 'created_at',
                        scopedSlots: { customRender: 'created_at' },
                    },
                    {
                        title: 'Actions',
                        dataIndex: 'actions',
                        key: 'actions',
                        scopedSlots: { customRender: 'actions' },
                    },
                    
                ],

                selectedChannelType: 'voice',

                selectedChannelProvider: 'twilio',

                newProjectName: '',

                newProjectDescription: '',

                total_project_customers: 0,

                searchTerm: '',
			}
		},
	})

</script>

<style lang="scss">

.selector-thick {
    width: 100%;

    height: 40px
}
</style>